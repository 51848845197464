<template>
    <div class="test-result">
        <div class="test-result__item">
            <div class="control">
                <img
                    src="/img/icons/true.svg"
                    class="control__img"
                    v-if="test.errors_check"
                />
                <img src="/img/icons/false.svg" class="control__img" v-else />
                <div>
                    <div>
                        {{ test.right_answers_count }} из
                        {{ test.questions_count }}
                    </div>
                    <div class="text-gray">
                        Верных ответов
                        <template v-if="!test.errors_check"
                            >(недостаточно)</template
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="test-result__item">
            <div class="control">
                <img
                    src="/img/icons/true.svg"
                    class="control__img"
                    v-if="test.result"
                />
                <img src="/img/icons/false.svg" class="control__img" v-else />
                <div>
                    <div>{{ test.percent }}</div>
                    <div class="text-gray">Общий результат</div>
                </div>
            </div>
        </div>
        <div class="test-result__item">
            <div class="control">
                <img
                    src="/img/icons/true.svg"
                    class="control__img"
                    v-if="test.time_check"
                />
                <img src="/img/icons/false.svg" class="control__img" v-else />
                <div>
                    <div>
                        {{ test.user_time_format }} из
                        {{ test.max_time_format }}
                    </div>
                    <div class="text-gray">Время прохождения</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        test: {
            type: Object,
            default: () => {}
        }
    }
};
</script>
