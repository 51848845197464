<template>
    <div class="tab-content">
        <div class="text-wrapper text-wrapper--remark">
            <svg class="icon icon-Warning ">
                <use xlink:href="#Warning"></use>
            </svg>
            Открыв билет, вы запустите таймер отсчета времени, постарайтесь ответить на все вопросы за отведенное время
        </div>
        <div class="grid-list">
            <CardTest
                v-for="(test, index) in training"
                :key="test.test_id"
                :id="index + 1"
                :test="test"
                :previousTest="isIntermediateTesting ? training[index - 1] : null"
                :isIntermediateTesting="isIntermediateTesting"
            />
        </div>
    </div>
</template>

<script>
import CardTest from "@/pages/personal/components/card-test"

export default {
    components: { CardTest },

    props: {
        training: Array,
        isIntermediateTesting: Boolean,
    },
}
</script>


