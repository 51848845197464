<template>
    <div class="card-border card-border--test" v-show="isShowTest">
        <div class="card-border__header">
            <img src="/img/icons/Note.svg" />
            {{ isTesting ? test.title : "Тест " + id }}
        </div>
        <div class="card-border__body">
            <div class="card-border__row">
                <div class="row-grid">
                    <svg class="icon icon-Question ">
                        <use xlink:href="#Question"></use>
                    </svg>
                    {{ test.questions_count }} вопросов
                </div>
                <div class="row-grid">
                    <svg class="icon icon-clock ">
                        <use xlink:href="#clock"></use>
                    </svg>
                    {{ test.max_time_format }}
                </div>
            </div>
            <div class="row-grid">
                <div class="text-gray">Результат</div>
                <span>{{ test.result }}</span>
            </div>
            <div class="row-grid">
                <div class="text-gray">Ошибки</div>
                <span>{{ test.errors_count }}</span>
            </div>
        </div>
        <button class="btn btn-white" @click.prevent="startTest">
            {{ isResolved ? "Смотреть результаты" : "Решить" }}
        </button>
    </div>

    <TestModal
        v-if="isShowModal"
        @close="isShowModal = false"
        :isIntermediateTesting="isIntermediateTesting"
    />
</template>

<script>
import TestModal from "@/components/modals/test/test-modal";

export default {
    components: { TestModal },

    props: {
        test: Object,
        previousTest: Object,
        isIntermediateTesting: Boolean,
        id: Number
    },

    data() {
        return { isShowModal: false };
    },

    computed: {
        isResolved() {
            return this.test.result !== "-";
        },
        isShowTest() {
            if (this.previousTest) return this.previousTest.result === "Зачет";
            else return true;
        },
        isTesting() {
            return this.$route.hash === "#testing"
        }
    },

    methods: {
        startTest() {
            this.$store
                .dispatch("TEST_FETCH_QUESTIONS", {
                    testId: this.test.test_id,
                    isIntermediate: Number(this.isIntermediateTesting)
                })
                .then(() => {
                    this.isShowModal = true;
                });
        }
    }
};
</script>
