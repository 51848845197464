<template>
    <div class="form-default__fields" data-simplebar>
        <div class="helper-content" v-html="activeQuestion.additional_text"></div>

        <div
            :class="isResolved ? 'control' : 'input-group'"
            v-for="option in activeQuestion.answers"
            :key="option.answer_id"
        >
            <label class="radio" v-if="!isResolved">
                <input
                    name="radio"
                    type="radio"
                    :value="option"
                    v-model="answer"
                />
                <span class="radio__indicator"></span>
                <span class="radio__description">{{ option.text }}</span>
            </label>

            <template v-else>
                <div
                    class="control__indicator"
                    v-if="
                        option.answer_id !== trueAnswerId &&
                            option.answer_id !== userAnswerId
                    "
                ></div>
                <img
                    src="/img/icons/true.svg"
                    class="control__img"
                    v-if="trueAnswerId === option.answer_id"
                />
                <img
                    src="/img/icons/false.svg"
                    class="control__img"
                    v-if="!isTrueAnswered && userAnswerId === option.answer_id"
                />
                <span>{{ option.text }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import "simplebar-vue/dist/simplebar-vue.js";

export default {
    props: {
        activeQuestion: {
            type: Object,
            default: () => {}
        },
        isResolved: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
        const helperHeight = document.querySelector('.helper-content').clientHeight
        const component = document.querySelector('.modal--test .form-default__fields')

        component.style.maxHeight = component.clientHeight + helperHeight + 'px'
        console.log(helperHeight)
    },

    computed: {
        trueAnswerId() {
            return this.activeQuestion.right_answer_id;
        },
        isTrueAnswered() {
            return (
                this.activeQuestion.right_answer_id ===
                this.activeQuestion.user_answer_id
            );
        },
        userAnswerId() {
            return this.activeQuestion.user_answer_id;
        }
    },

    data() {
        return {
            answer: null
        };
    },

    watch: {
        answer() {
            this.$emit("update:modelValue", this.answer);
        },
        activeQuestion() {
            this.answer = null;
        }
    }
};
</script>
