<template>
    <div class="tab-content">
        <div class="courses-list">
            <a :href="'https://mait-nauka.ru/' + file.path" target="_blank" class="document-link" v-for="(file, index) in files" :key="index" download>
                <svg class="icon icon-google-drive ">
                    <use xlink:href="#google-drive"></use>
                </svg>
                {{ file.name }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        files: Array
    }
}
</script>
