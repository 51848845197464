<template>
    <div class="section-tile">
        <button
            v-for="(item, index) in questions"
            class="section-tile__item"
            :class="classes(item)"
            :disabled="resultId.includes(item.question_id)"
            :key="index"
            @click.prevent="$emit('update:next', index)"
        >
            {{ index + 1 }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        activeQuestionId: {
            type: Number,
            default: 0
        },
        resultId: {
            type: Array,
            default: () => []
        },
        questions: {
            type: Array,
            default: () => []
        },
        isResolved: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        classes(item) {
            const list = [];

            if (this.activeQuestionId === item.question_id) list.push("active");

            if (this.isResolved)
                list.push(
                    item.right_answer_id === item.user_answer_id
                        ? "section-tile__item--true"
                        : "section-tile__item--false"
                );

            return list.join(" ");
        }
    }
};
</script>
