<template>
    <div class="tab-content">
        <div class="grid-list">
            <div class="lesson-card" v-for="(video, index) in videos" :key="index">
                <a :href="video.url" target="_blank" class="lesson-card__link"></a>
                <div class="lesson-card__cover">
                    <img src="/img/test_image.jpg">
                    <div class="marker">12:43</div>
                </div>
                <div class="lesson-card__title">{{ video.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        videos: Array
    }
}
</script>
