<template>
    <ButtonBack />

    <div class="section-grid">
        <div class="main-info">
            <p class="title-second">Курс {{ course.code }}</p>
            <div class="main-info__text">{{ course.name }}</div>
        </div>
        <div class="card-border card-border--border">
            <div class="card-border__title text-big strong">Советы</div>
            <p>
                Прежде, чем преступить к аттестации, пройдите тренировочное
                тестирование. Предоставленные вопросы включены в промежуточную и
                итоговую аттестацию.
            </p>
            <div class="card-border__note strong">
                Допустимое количество ошибок - {{ course.errors_count }}
            </div>
        </div>
        <div class="row">
            <Period :dates="course.dates" :classes="'row__item'" v-if="course.dates.start && course.dates.end" />
            <div class="text-gray row__item">{{ course.hours }}</div>
        </div>
    </div>

    <div class="tabs js-tabs">
        <div class="tabs__items">
            <button
                class="tabs__item"
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{ active: $route.hash === tab.hash }"
                @click.prevent="$router.push({ hash: tab.hash })"
            >
                {{ tab.name }}
            </button>
        </div>
    </div>

    <TabBooks v-if="$route.hash === '#books'" :files="course.files" />
    <TabWebinars v-if="$route.hash === '#webinars'" :videos="course.videos" />
    <TabTesting v-if="$route.hash === '#testing'" :training="tests.training" />
    <TabTesting
        v-if="$route.hash === '#attestation'"
        :training="isExam ? [tests.exam] : tests.intermediate"
        :isIntermediateTesting="!isExam"
    />

</template>

<script>
import TabBooks from "./tabs/tab-books";
import TabWebinars from "./tabs/tab-webinars";
import TabTesting from "./tabs/tab-testing";
import Period from "@/pages/personal/components/period"

import store from "@/store/index.js";

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_GET_ITEM_COURSE", to.params.slug).then(() => {
            next();
        });
    },

    components: {
        TabBooks,
        TabWebinars,
        TabTesting,
        Period
    },

    watch: {
        isExam() {
            if (this.isExam) this.tabs[3].name = "Итоговая аттестация"
        }
    },

    computed: {
        course() {
            return this.$store.state.student.course;
        },
        tests() {
            return this.course.tests;
        },
        isExam() {
            return !!this.tests.exam.test_id;
        }
    },

    data() {
        return {
            tabs: [
                {
                    name: "Методические пособия",
                    hash: "#books"
                },
                {
                    name: "Обучающие вебинары",
                    hash: "#webinars"
                },
                {
                    name: "Тренировочное тестирование",
                    hash: "#testing"
                },
                {
                    name: "Промежуточная аттестация",
                    hash: "#attestation"
                },
            ]
        }
    },

    mounted() {
        if (this.isExam) this.tabs[3].name = "Итоговая аттестация"
    }
};
</script>
