<template>
    <BaseModal :classes="'modal--test'">
        <div class="test">
            <div class="test__header">
                <div class="test__title">
                    <img src="/img/icons/Note.svg" />
                    <div class="text-big">{{ test.title }}</div>
                </div>
                <div class="test__row">
                    <div class="row-grid" v-if="!isResolved">
                        <span>Времени осталось</span>
                        <TimeCounter
                            :time="test.max_time_in_secs"
                            @update:time="recordTestTime"
                        />
                    </div>
                    <button
                        class="btn test__btn-close"
                        @click.prevent="
                            isResolved
                                ? $emit('close')
                                : (isShowActionModal = true)
                        "
                    >
                        Завершить
                        <div class="btn__icon">
                            <svg class="icon icon-close-1 ">
                                <use xlink:href="#close-1"></use>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>

            <div class="test__message" v-if="isResolved" :class="test.result ? 'test__message--success' : 'test__message--error'">
                {{
                    test.result && test.is_exam
                        ? 'Обучение завершено! Квалификационный документ на оформлении. Скан-копия и оригинал предоставляются при 100% оплате.'
                        : test.result
                        ? 'Тест был пройден успешно'
                        : 'Тест не пройден'
                }}
            </div>

            <QuestionButtons
                @update:next="goToQuestion"
                :activeQuestionId="activeQuestion.question_id"
                :resultId="resultId"
                :questions="questions"
                :isResolved="isResolved"
            />

            <div class="test__subtitle text-gray">
                Вопрос {{ questionIndex + 1 }}
            </div>
            <p>{{ activeQuestion.text }}</p>

            <div class="form-default">
                <QuestionItem
                    v-if="canShowResult"
                    v-model="answer"
                    :activeQuestion="activeQuestion"
                    :isResolved="isResolved"
                />

                <div class="form-default__grid">
                    <div class="form-default__submit" v-if="canRepassTest">
                        <button
                            class="btn btn-white"
                            v-if="isResolved"
                            :disabled="!test.can_repass"
                            @click.prevent="resolveAgain"
                        >
                            Решить заново
                        </button>

                        <template v-else>
                            <button
                                class="btn btn-red"
                                v-if="!isAllAnswered"
                                @click.prevent="writeAnswer"
                                :disabled="!answer"
                            >
                                Ответить
                            </button>
                            <button
                                class="btn btn-red"
                                v-if="isAllAnswered"
                                @click.prevent="checkAnswers"
                            >
                                Проверить ответы
                            </button>
                            <button
                                class="btn btn-white"
                                v-if="!isLastQuestion && !isAllAnswered"
                                @click.prevent="nextQuestion"
                            >
                                Пропустить
                            </button>
                        </template>
                    </div>

                    <TestResult v-if="isResolved" :test="test" />
                </div>
            </div>
        </div>

        <BaseModal
            :classes="'modal--error'"
            v-if="isShowInfoModal"
            @close="isShowInfoModal = false"
        >
            <div class="modal__icon">
                <svg class="icon icon-clock ">
                    <use xlink:href="#clock"></use>
                </svg>
            </div>
            <div class="modal__title subtitle js-error-modal-title">
                Время истекло
            </div>
            <div class="modal__description js-error-modal-description">
                Ваш результат записан
            </div>
        </BaseModal>
        <BaseModal v-if="isShowActionModal" @close="isShowActionModal = false">
            <div class="modal__title subtitle js-error-modal-title">
                Вы ответили не на все вопросы.
            </div>
            <div class="modal__description js-error-modal-description">
                Вы уверены, что хотите завершить тестирование?
            </div>
            <div class="modal__btn">
                <button class="btn btn-red" @click.prevent="$emit('close')">
                    Завершить
                </button>
                <button
                    class="btn btn-white"
                    @click.prevent="isShowActionModal = false"
                >
                    Продолжить
                </button>
            </div>
        </BaseModal>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/base-modal";
import TimeCounter from "@/components/time-counter";
import QuestionButtons from "@/components/modals/test/question-buttons";
import QuestionItem from "@/components/modals/test/question-item";
import TestResult from "@/components/modals/test/test-result";

export default {
    props: {
        isIntermediateTesting: Boolean
    },

    components: {
        BaseModal,
        TimeCounter,
        QuestionButtons,
        QuestionItem,
        TestResult
    },

    computed: {
        test() {
            return this.$store.state.test.currentTest;
        },
        questions() {
            return this.test.questions;
        },
        activeQuestion() {
            return this.questions[this.questionIndex];
        },
        questionsCount() {
            return this.questions.length;
        },
        isLastQuestion() {
            return this.questionsCount == this.questionIndex + 1;
        },
        isAllAnswered() {
            return (
                this.questionsCount == Object.keys(this.results.answers).length
            );
        },
        isResolved() {
            return this.$store.state.test.isResolved;
        },
        isTimeOver() {
            return this.results.user_time_in_sec >= this.test.max_time_in_secs;
        },
        canShowResult() {
            return this.isResolved ? this.$route.hash === "#testing" : true
        },

        isTraining() {
            return this.$store.state.student.course.tests.training.some(({ test_id }) => test_id === this.test.test_id)
        },
        
        canRepassTest() {
            return (this.isTraining && !this.isIntermediateTesting) || !this.test.result
        }
    },

    data() {
        return {
            answer: null,
            questionIndex: 0,
            results: {
                test_id: null,
                user_time_in_sec: null,
                is_intermediate: this.isIntermediateTesting,
                answers: {}
            },
            resultId: [],
            isShowInfoModal: false,
            isShowActionModal: false
        };
    },

    methods: {
        goToQuestion(index) {
            this.questionIndex = index;
            this.answer = null;
        },
        nextQuestion() {
            this.goToQuestion(this.questionIndex + 1);
        },
        writeAnswer() {
            if (this.answer) {
                this.resultId.push(this.activeQuestion.question_id);
                this.results.answers[
                    this.activeQuestion.question_id
                ] = this.answer.answer_id;
                if (!this.isLastQuestion) this.nextQuestion();
            }
        },
        checkAnswers() {
            this.results.test_id = this.test.test_id;
            this.$store
                .dispatch("TEST_CHECK_ANSWERS", this.results)
                .then(() => {
                    if (this.isTimeOver) this.isShowInfoModal = true;
                    this.questionIndex = 0;
                    this.resultId = [];
                    this.$store.dispatch(
                        "PERSONAL_GET_ITEM_COURSE",
                        this.$route.params.slug
                    );
                });
        },
        recordTestTime(time) {
            this.results.user_time_in_sec = time;
        },
        resolveAgain() {
            this.$store.commit("TEST_IS_RESOLVE", false);
            this.results.user_time_in_sec = null;
            this.results.answers = {};
            this.questionIndex = 0;
        }
    },

    watch: {
        isTimeOver() {
            this.checkAnswers();
        }
    }
};
</script>
